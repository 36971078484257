import { parse } from 'querystring';
import Airports from "@/common/airports";

const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl = (path: string): boolean => reg.test(path);

export const isAntDesignPro = (): boolean => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }
  return window.location.hostname === 'preview.pro.ant.design';
};

// For the official demo site, it is used to turn off features that are not needed in the real development environment
export const isAntDesignProOrDev = (): boolean => {
  const { NODE_ENV } = process.env;
  if (NODE_ENV === 'development') {
    return true;
  }
  return isAntDesignPro();
};

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export function getAccessToken(): string | null {
  return localStorage ? localStorage.getItem('access-token') : null;
}

export function setAccessToken(token: string): void {
  localStorage.setItem('access-token', token);
}

export function clearAccessToken(): void {
  localStorage.removeItem('access-token');
}

export const getAirportName = (code: string) => {
  const airport = Airports.find((a) => a.value === code);

  return airport ? airport.label : '';
}

export const formatFlightTime = (timeHour: number, timeMinute: number) => {
  return `${timeHour < 10 ? `0${timeHour}` : timeHour}h${timeMinute < 10 ? `0${timeMinute}` : timeMinute}`;
}
