// @ts-nocheck

import HomeOutlined from '@ant-design/icons/HomeOutlined';
import ShoppingCartOutlined from '@ant-design/icons/ShoppingCartOutlined';
import ShopOutlined from '@ant-design/icons/ShopOutlined';
import DollarOutlined from '@ant-design/icons/DollarOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import AppstoreAddOutlined from '@ant-design/icons/AppstoreAddOutlined';
import CarOutlined from '@ant-design/icons/CarOutlined';
import ShoppingOutlined from '@ant-design/icons/ShoppingOutlined';
import SwitcherOutlined from '@ant-design/icons/SwitcherOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import FileProtectOutlined from '@ant-design/icons/FileProtectOutlined';
import ReadOutlined from '@ant-design/icons/ReadOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import AuditOutlined from '@ant-design/icons/AuditOutlined';
import ContactsOutlined from '@ant-design/icons/ContactsOutlined';
import HighlightOutlined from '@ant-design/icons/HighlightOutlined';
import ToolOutlined from '@ant-design/icons/ToolOutlined';
import FundProjectionScreenOutlined from '@ant-design/icons/FundProjectionScreenOutlined'

export default {
  HomeOutlined,
ShoppingCartOutlined,
ShopOutlined,
DollarOutlined,
LockOutlined,
AppstoreAddOutlined,
CarOutlined,
ShoppingOutlined,
SwitcherOutlined,
UserOutlined,
FileProtectOutlined,
ReadOutlined,
TeamOutlined,
AuditOutlined,
ContactsOutlined,
HighlightOutlined,
ToolOutlined,
FundProjectionScreenOutlined
}
    