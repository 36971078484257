import type { Reducer, Effect } from 'umi';
import _ from 'lodash';

import { fetchSignIn, updateSignIn, deleteSignIn } from './services';
import type { FetchSignInResp, SignInStateType } from './sign-in-setting.data';
import type { ConnectState } from '@/models/connect';
import { message } from 'antd';

export type SignInModelType = {
  namespace: string;
  state: SignInStateType;
  effects: {
    fetchSignIn: Effect;
    updateSignIn: Effect;
    deleteSignIn: Effect;
  };
  reducers: {
    fetchSignInsSuccess: Reducer<SignInStateType>;
  };
};

const Model: SignInModelType = {
  namespace: 'signIn',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchSignIn({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.signIn.query);

      const query = _.pickBy({ ...currentQuery, ...updatedParams }, (value) => value !== '');

      const response: FetchSignInResp = yield call(fetchSignIn, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchSignInsSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateSignIn({ payload: { tourSellId, data } }, { call }) {
      yield call(updateSignIn, tourSellId, data);
    },
    *deleteSignIn({ payload }, { call, put }) {
      const response = yield call(deleteSignIn, payload);

      if (response?.success) {
        message.success('The sign in setting has been deleted.').then(() => {});
        yield put({
          type: 'fetchSignIns',
        });
      }
    },
  },
  reducers: {
    fetchSignInsSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
