import request from '@/utils/request';
import type {FetchSignInResp} from "@/pages/Flight/SignInSetting/sign-in-setting.data";

export async function createSignIn(params: any) {
  return request.post(`/flight/sign-in-settings`, {
    data: params,
  });
}

export async function fetchSignIn(params?: any): Promise<FetchSignInResp> {
  return request.get('/flight/sign-in-settings', {
    params,
  });
}

export async function updateSignIn(id: string, params: any) {
  return request.patch(`/flight/sign-in-settings/${id}`, {
    data: params,
  });
}

export async function deleteSignIn(id: string) {
  return request.delete(`/flight/sign-in-settings/${id}`);
}
