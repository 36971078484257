import request from '@/utils/request';
import {
  FetchVehicleServiceResp,
  VehicleService,
} from '@/pages/VehicleService/vehicle-service.data';

export async function createVehicleService(params: any) {
  return request.post(`/cms/vehicle-services`, {
    data: params,
  });
}

export async function fetchVehicleServices(params?: any): Promise<FetchVehicleServiceResp> {
  return request.get('/cms/vehicle-services', {
    params,
  });
}

export async function getVehicleServiceDetail(id: string): Promise<VehicleService> {
  return request.get(`/cms/vehicle-services/${id}`);
}

export async function updateVehicleService(id: string, params?: any): Promise<any> {
  return request.patch(`/cms/vehicle-services/${id}`, {
    data: params,
  });
}

export async function deleteVehicleService(id: string): Promise<any> {
  return request.delete(`/cms/vehicle-services/${id}`);
}
