import _ from 'lodash';
import type {Reducer, Effect} from 'umi';
import {incomeStatementReport} from './services';
import type {ConnectState} from '@/models/connect';
import type {BookingStateType, FetchBookingResp} from "@/pages/Flight/Booking/booking.data";

export type AccountingModelType = {
  namespace: string;
  state: BookingStateType;
  effects: {
    incomeStatementReport: Effect;
  };
  reducers: {
    incomeStatementReportSuccess: Reducer<BookingStateType>;
  };
};

const Model: AccountingModelType = {
  namespace: 'accounting',

  state: {
    list: [],
    query: {},
    total: 0,
  },

  effects: {
    * incomeStatementReport({payload: updatedParams}, {call, put, select}) {
      const currentQuery = yield select((state: ConnectState) => state.user.query);

      const query = _.pickBy({...currentQuery, ...updatedParams}, (value) => value !== '');

      const response: FetchBookingResp = yield call(incomeStatementReport, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchBookingsSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
  },
  reducers: {
    incomeStatementReportSuccess(state, {payload}) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
