import { stringify } from 'querystring';
import type { Reducer, Effect } from 'umi';
import { history } from 'umi';

import { getProfile, google, facebook, login } from '@/pages/Auth/auth';
import type { LoginResp } from '@/pages/Auth/auth';
import { setAuthority } from '@/utils/authority';
import { clearAccessToken, getPageQuery, setAccessToken } from '@/utils/utils';
import { UserRole } from '@/common/enum';
import { CurrentUserDto } from '@/common/data.d/user.data.d';
import { notification } from 'antd';

export type CurrentUser = {
  id?: string;
  avatar?: string;
  name?: string;
  email?: string;
  partner?: string;
  roles?: UserRole[];
};

export type StateType = {
  status?: 'ok' | 'error';
  type?: string;
  role?: string;
  user?: CurrentUser;
};

export type LoginModelType = {
  namespace: string;
  state: StateType;
  effects: {
    login: Effect;
    google: Effect;
    facebook: Effect;
    getProfile: Effect;
    loginSucess: Effect;
    logout: Effect;
  };
  reducers: {
    changeLoginStatus: Reducer<StateType>;
    getProfileSuccess: Reducer<StateType>;
  };
};

const Model: LoginModelType = {
  namespace: 'auth',

  state: {
    status: undefined,
  },

  effects: {
    *login({ payload }, { call, put }) {
      const response: LoginResp = yield call(login, payload);
      if (response.accessToken) {
        yield put({
          type: 'loginSucess',
          payload: response,
        });
      }
    },

    *google({ payload }, { call, put }) {
      const response: LoginResp = yield call(google, payload);
      if (response && response.accessToken) {
        yield put({
          type: 'loginSucess',
          payload: response,
        });
      }
    },

    *facebook({ payload }, { call, put }) {
      const response: LoginResp = yield call(facebook, payload);
      if (response && response.accessToken) {
        yield put({
          type: 'loginSucess',
          payload: response,
        });
      }
    },

    *getProfile(_, { call, put }) {
      // @ts-ignore
      const response = yield call(getProfile);
      yield put({
        type: 'getProfileSuccess',
        payload: response,
      });
    },

    *loginSucess({ payload }, { put }) {
      const user = CurrentUserDto.fromUser(payload.user);
      if (user.isOnlyUser) {
        notification.error({
          message: 'Forbiden',
          description: 'You do not have permission to access this page',
        });
        return;
      }

      setAccessToken(payload.accessToken);
      yield put({
        type: 'changeLoginStatus',
        payload,
      });

      const goTo = 'dashboard/list';

      // Login successfully
      const urlParams = new URL(window.location.href);
      const params = getPageQuery();
      let { redirect } = params as { redirect: string };
      if (redirect) {
        const redirectUrlParams = new URL(redirect);
        if (redirectUrlParams.origin === urlParams.origin) {
          redirect = redirect.substr(urlParams.origin.length);
        } else {
          window.location.href = goTo;
          return;
        }
      }
      window.location.href = redirect || goTo;
    },

    logout() {
      const { redirect } = getPageQuery();

      setAuthority('');

      clearAccessToken();

      if (window.location.pathname !== '/auth/login' && !redirect) {
        history.replace({
          pathname: '/auth/login',
          search: stringify({
            redirect: window.location.href,
          }),
        });
      }
    },
  },

  reducers: {
    changeLoginStatus(state, { payload }) {
      setAuthority(payload.user.roles);
      return {
        ...state,
        status: 'ok',
        user: payload.user,
      };
    },
    getProfileSuccess(state, { payload }) {
      setAuthority(payload.roles);
      return {
        ...state,
        user: payload,
      };
    },
  },
};

export default Model;
