import request from '@/utils/request';
import type { FetchUserResp } from './user.data';

export async function fetchUsers(params?: any): Promise<FetchUserResp> {
  return request.get('/flight/users', {
    params,
  });
}
export async function getUserDetail(id: string) {
  return request.get(`/flight/users/${id}`);
}

export async function createUser(params: any) {
  return request.post(`/flight/users/create`, {
    data: params,
  });
}

export async function updateUser(id: string, params: any) {
  return request.patch(`/flight/users/${id}`, {
    data: params,
  });
}
export async function deleteUser(id: string) {
  return request.delete(`/flight/users/${id}`);
}
