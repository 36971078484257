import request from '@/utils/request';
import {BannerModel, FetchBannersResp} from "@/pages/Banner/banner.data";

const BANNERS_API = 'banners';

export async function fetchBanners(params?: any): Promise<FetchBannersResp> {
  return request.get(`/cms/${BANNERS_API}`, {
    params,
  });
}

export async function getBanner(id: string): Promise<BannerModel> {
  return request.get(`/cms/${BANNERS_API}/${id}`);
}

export async function updateBanner(id: string, data: any): Promise<any> {
  return request.patch(`/cms/${BANNERS_API}/${id}`, {
    data,
  });
}
