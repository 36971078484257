import request from '@/utils/request';
import type {FetchFlightResp, FetchFlightParams, TicketDataType, RouteDataType} from "@/pages/Flight/Booking/booking.data";
import {PaxDataType} from "@/pages/Flight/Booking/booking.data";

export async function createPermission(params: any) {
  return request.post(`/flight/permissions`, {
    data: params,
  });
}

export async function fetchFlights(params?: FetchFlightParams): Promise<FetchFlightResp> {
  return request.get('/flight/bookings', {
    params,
  });
}

export async function searchFlights(params?: FetchFlightParams): Promise<FetchFlightResp> {
  return request.get('/flight/bookings/search-flight', {
    params,
  });
}

export async function offerPrice(params: any) {
  return request.post('/flight/bookings/offer-price', {
    data: params,
  });
}

export async function getAncillary(params: any) {
  return request.post('/flight/bookings/get-ancillary', {
    data: params,
  });
}

export async function issueTicket(pnr: string): Promise<{
  status: boolean,
  message?: string,
  ticket?: TicketDataType
}> {
  return request.post(`/flight/bookings/issue-ticket/${pnr}`);
}

export async function retrievePnr(pnr: string): Promise<{
  paxs: PaxDataType[],
  routes: RouteDataType[]
}> {
  return request.post(`/flight/bookings/retrieve-pnr/${pnr}`);
}

export async function splitPassenger(params: any): Promise<{
  status: boolean,
  message?: string,
  ticket?: TicketDataType
}> {
  return request.post(`/flight/bookings/split-passenger`, {
    data: params
  });
}

export async function listBooking(params: any) {
  return request.get('/flight/bookings/list-booking', {
    data: params,
  });
}

export async function bookFlight(params: any) {
  return request.post('/flight/bookings/book-flight', {
    data: params,
  });
}

export async function verifyFlight(params: any) {
  return request.post('/flight/bookings/verify-flight', {
    data: params,
  });
}

export async function getTicket(id: string): Promise<TicketDataType> {
  return request.get(`/flight/tickets/${id}`);
}

export async function cancelBooking(params: any) {
  return request.post(`/flight/bookings/cancel-booking`, {
    data: params,
  });
}

export async function exportTicket(params: any) {
  return request.post(`/flight/tickets/export`, {
    data: params,
  });
}

export async function searchLowestFare(route: string) {
  return request.get(`/flight/lowest-fares?route=${route}`);
}
