import request from '@/utils/request';
import type {FetchSubAgentResp, SubAgentDataType} from "@/pages/Flight/SubAgent/sub-agent.data";

export async function createSubAgent(params: any) {
  return request.post(`/flight/agents`, {
    data: params,
  });
}

export async function fetchSubAgents(params?: any): Promise<FetchSubAgentResp> {
  return request.get('/flight/agents', {
    params,
  });
}

export async function fetchDepositMonitors(params?: any): Promise<{ list: SubAgentDataType[] }> {
  return request.get('/flight/agents/deposit-monitors', {
    params,
  });
}

export async function fetchMyAgent(params?: any): Promise<SubAgentDataType> {
  return request.get('/flight/agents/my-agent', {
    params,
  });
}

export async function updateSubAgent(id: string, params: any) {
  return request.patch(`/flight/agents/${id}`, {
    data: params,
  });
}

export async function deleteSubAgent(id: string) {
  return request.delete(`/flight/agents/${id}`);
}
