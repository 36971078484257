import {UserRole} from '../enum';
import {Partner} from '@/pages/Partner/partner.data';
import {RoleDataType} from '@/pages/Role/role.data';

export class CurrentUserDto {
  id: string;
  name: string;
  email: string;
  avatar: string;
  partner?: string;
  roles: UserRole[];
  myPartner?: Partner;
  myRole?: RoleDataType;

  constructor(init?: Partial<CurrentUserDto>) {
    Object.assign(this, init);
  }

  static fromUser(user: User) {
    return new CurrentUserDto({
      id: user.id,
      name: user.name,
      email: user.email,
      myRole: user.myRole,
      avatar: user.avatar,
      partner: user.partner,
      roles: user.roles || [],
      myPartner: user.myPartner,
    });
  }

  get isUser(): boolean {
    return this.roles.includes(UserRole.USER);
  }

  get isOnlyUser(): boolean {
    return this.roles.includes(UserRole.USER) && this.roles.length === 1;
  }

  get isAdmin(): boolean {
    return this.roles.includes(UserRole.ADMIN);
  }

  get isSuperAdmin(): boolean {
    return this.roles.includes(UserRole.SUPER_ADMIN);
  }

  get isMod(): boolean {
    return this.roles.includes(UserRole.MOD);
  }

  get isLodgingService(): boolean {
    return this.roles.includes(UserRole.LODGING_SERVICE);
  }

  get isTravelerService(): boolean {
    return this.roles.includes(UserRole.TRAVELER_SERVICE);
  }

  get isNotAgentAndCollaborator(): boolean {
    return (
      !this.roles.includes(UserRole.TRAVELER_COLLABORATOR) &&
      !this.roles.includes(UserRole.TRAVELER_AGENT)
    );
  }

  get isModAndAbove(): boolean {
    return this.isAdminAndAbove || this.isMod;
  }

  get isAgentFly(): boolean {
    return window.location.origin === 'https://agentflytrip.com';
  }

  get isAdminAndAbove(): boolean {
    return this.isAdmin || this.isSuperAdmin;
  }

  checkPermission(permission: string): boolean {
    if (this.isTravelerService || this.isModAndAbove) return true;

    if (!this?.myRole) return false;

    if (this.isAgentFly) return false;

    return this.myRole?.permissions?.includes(permission);
  }

  get isTourIndex(): boolean {
    return this.checkPermission('partner_tour_index');
  }

  get isTourCreate(): boolean {
    if (this.isTravelerService) return true;

    return this.myRole?.permissions.includes('partner_tour_create');
  }

  get isTourUpdate(): boolean {
    if (this.isTravelerService) return true;

    return this.myRole?.permissions.includes('partner_tour_update');
  }

  get isTourCopy(): boolean {
    if (this.isTravelerService) return true;

    return this.myRole?.permissions.includes('partner_tour_copy');
  }

  get isTourDelete(): boolean {
    if (this.isTravelerService) return true;

    return this.myRole?.permissions.includes('partner_tour_delete');
  }

  get isTourDetail(): boolean {
    if (this.isTravelerService) return true;

    return this.myRole?.permissions.includes(`partner_tour_detail`);
  }

  // order
  get isOrderIndex(): boolean {
    return this.checkPermission('order_index');
  }

  get isOrderCreate(): boolean {
    return this.checkPermission('order_create');
  }

  get isOrderUpdate(): boolean {
    return this.checkPermission('order_update');
  }

  get isOrderDelete(): boolean {
    return this.checkPermission('order_delete');
  }

  get isOrderDetail(): boolean {
    return this.checkPermission('order_detail');
  }

  get isOrderReceipt(): boolean {
    return this.checkPermission('order_receipt');
  }

  get isOrderPrint(): boolean {
    return this.checkPermission('order_print');
  }

  // visa
  get isVisaIndex(): boolean {
    return this.checkPermission('visa_order_index');
  }

  get isVisaCreate(): boolean {
    return this.checkPermission('visa_create');
  }

  get isVisaUpdate(): boolean {
    return this.checkPermission('visa_update');
  }

  get isVisaDelete(): boolean {
    return this.checkPermission('visa_delete');
  }

  get isVisaDetail(): boolean {
    return this.checkPermission('visa_detail');
  }

  get isVisaReceipt(): boolean {
    return this.checkPermission('visa_receipt');
  }

  get isVisaPrint(): boolean {
    return this.checkPermission('visa_print');
  }

  get partnerLogo(): boolean {
    return this?.myPartner?.logo || '';
  }

  // combo order
  get isComboOrderIndex(): boolean {
    return this.checkPermission('combo_order_index');
  }

  get isComboOrderCreate(): boolean {
    return this.checkPermission('combo_order_create');
  }

  get isComboOrderUpdate(): boolean {
    return this.checkPermission('combo_order_update');
  }

  get isComboOrderDelete(): boolean {
    return this.checkPermission('combo_order_delete');
  }

  get isComboOrderReceipt(): boolean {
    return this.checkPermission('combo_order_receipt');
  }

  get isComboOrderPrint(): boolean {
    return this.checkPermission('combo_order_print');
  }

  // hotel order
  get isHotelOrderIndex(): boolean {
    return this.checkPermission('hotel_order_index');
  }

  get isHotelOrderCreate(): boolean {
    return this.checkPermission('hotel_order_create');
  }

  get isHotelOrderUpdate(): boolean {
    return this.checkPermission('hotel_order_update');
  }

  get isHotelOrderDelete(): boolean {
    return this.checkPermission('hotel_order_delete');
  }

  get isHotelOrderReceipt(): boolean {
    return this.checkPermission('hotel_order_receipt');
  }

  get isHotelOrderPrint(): boolean {
    return this.checkPermission('hotel_order_print');
  }

  // role
  get isRoleIndex(): boolean {
    return this.checkPermission('role_index');
  }

  get isRoleCreate(): boolean {
    return this.checkPermission('role_create');
  }

  get isRoleUpdate(): boolean {
    return this.checkPermission('role_update');
  }

  get isRoleDelete(): boolean {
    return this.checkPermission('role_delete');
  }

  // cost
  get isCostIndex(): boolean {
    return this.checkPermission('cost_index');
  }

  get isCostCreate(): boolean {
    return this.checkPermission('cost_create');
  }

  get isCostUpdate(): boolean {
    return this.checkPermission('cost_update');
  }

  get isCostDelete(): boolean {
    return this.checkPermission('cost_delete');
  }

  get isCostPayment(): boolean {
    return this.checkPermission('cost_payment');
  }

  get isCostDetail(): boolean {
    return this.checkPermission('cost_detail');
  }

  // customer
  get isCustomerIndex(): boolean {
    return this.checkPermission('customer_index');
  }

  get isCustomerCreate(): boolean {
    return this.checkPermission('customer_create');
  }

  get isCustomerUpdate(): boolean {
    return this.checkPermission('customer_update');
  }

  get isCustomerDelete(): boolean {
    return this.checkPermission('customer_delete');
  }

  // cost category
  get isCostCategory(): boolean {
    return this.checkPermission('cost_category');
  }

  // supplier
  get isSupplierIndex(): boolean {
    return this.checkPermission('supplier_index');
  }

  get isSupplierCreate(): boolean {
    return this.checkPermission('supplier_create');
  }

  get isSupplierUpdate(): boolean {
    return this.checkPermission('supplier_update');
  }

  get isSupplierDelete(): boolean {
    return this.checkPermission('supplier_delete');
  }

  // staff
  get isStaffIndex(): boolean {
    return this.checkPermission('staff_index');
  }

  get isStaffCreate(): boolean {
    return this.checkPermission('staff_create');
  }

  get isStaffUpdate(): boolean {
    return this.checkPermission('staff_update');
  }

  get isStaffDelete(): boolean {
    return this.checkPermission('staff_delete');
  }

  // agent
  get isAgentIndex(): boolean {
    return this.checkPermission('agent_index');
  }

  get isAgentCreate(): boolean {
    return this.checkPermission('agent_create');
  }

  get isAgentUpdate(): boolean {
    return this.checkPermission('agent_update');
  }

  get isAgentDelete(): boolean {
    return this.checkPermission('agent_delete');
  }

  // activity_history
  get isActivityIndex(): boolean {
    return this.checkPermission('activity_history_index');
  }

  // ctv
  get isCtvIndex(): boolean {
    return this.checkPermission('collaborator_index');
  }

  get isCtvCreate(): boolean {
    return this.checkPermission('collaborator_create');
  }

  get isCtvUpdate(): boolean {
    return this.checkPermission('collaborator_update');
  }

  get isCtvDelete(): boolean {
    return this.checkPermission('collaborator_delete');
  }

  get isDashboard(): boolean {
    return this.checkPermission('dashboard_index');
  }

  get isHotelService(): boolean {
    return this.checkPermission('hotel_service_index');
  }

  get isHotelServiceCreate(): boolean {
    return this.checkPermission('hotel_service_create');
  }

  get isHotelServiceUpdate(): boolean {
    return this.checkPermission('hotel_service_update');
  }

  get isHotelServiceDelete(): boolean {
    return this.checkPermission('hotel_service_delete');
  }

  get isTicketService(): boolean {
    return this.checkPermission('ticket_service_index');
  }

  get isTicketServiceCreate(): boolean {
    return this.checkPermission('ticket_service_create');
  }

  get isTicketServiceUpdate(): boolean {
    return this.checkPermission('ticket_service_update');
  }

  get isTicketServiceDelete(): boolean {
    return this.checkPermission('ticket_service_delete');
  }

  get isVisaService(): boolean {
    return this.checkPermission('visa_service_index');
  }

  get isBanner(): boolean {
    return this.checkPermission('banner');
  }

  get isBlog(): boolean {
    return this.checkPermission('blog');
  }

  get isVisaServiceCreate(): boolean {
    return this.checkPermission('visa_service_create');
  }

  get isVisaServiceUpdate(): boolean {
    return this.checkPermission('visa_service_update');
  }

  get isVisaServiceDelete(): boolean {
    return this.checkPermission('visa_service_delete');
  }

  get isPolicyTour(): boolean {
    return this.checkPermission('policy_tour_index');
  }

  get isMenu(): boolean {
    return this.checkPermission('menu_index');
  }

  get isTourSell(): boolean {
    return this.checkPermission('tour_sell_index');
  }

  get isTourSellCreate(): boolean {
    return this.checkPermission('tour_sell_create');
  }

  get isFlight(): boolean {
    return this.checkPermission('flight');
  }

  get isTourSellUpdate(): boolean {
    return this.checkPermission('tour_sell_update');
  }

  get isTourSellDelete(): boolean {
    return this.checkPermission('tour_sell_delete');
  }

  get isTourSellStop(): boolean {
    return this.checkPermission('tour_sell_stop');
  }

  get isTourSellSetting(): boolean {
    return this.checkPermission('tour_sell_setting');
  }

  // flight
  get isFlightBookingTicket(): boolean {
    return this.checkPermission('flight_booking_ticket');
  }

  get isFlightExportTicket(): boolean {
    return this.checkPermission('flight_export_ticket');
  }

  get isFlightSearchBooking(): boolean {
    return this.checkPermission('flight_search_booking');
  }

  get isFlightDepositManager(): boolean {
    return this.checkPermission('flight_deposit_manager');
  }

  get isFlightViewDeposit(): boolean {
    return this.checkPermission('flight_view_deposit');
  }

  get isFlightConfirmDeposit(): boolean {
    return this.checkPermission('flight_confirm_deposit');
  }

  get isFlightInsurance(): boolean {
    return this.checkPermission('flight_insurance');
  }

  get isFlightScViewer(): boolean {
    return this.checkPermission('flight_sc_viewer');
  }

  get isFlightVoidViewAllTicket(): boolean {
    return this.checkPermission('flight_void_view_all_ticket');
  }

  get isFlightAdminNew(): boolean {
    return this.checkPermission('flight_admin_new');
  }

  get isFlightRefundTicket(): boolean {
    return this.checkPermission('flight_refund_ticket');
  }

  get isFlightOnlyShowInfo(): boolean {
    return this.checkPermission('flight_only_show_info');
  }

  get isFlightVoidVna(): boolean {
    return this.checkPermission('flight_void_vna');
  }

  get isFlightF2ViewDeposit(): boolean {
    return this.checkPermission('flight_f2_view_deposit');
  }

  get isFlightUserNotSendEmail(): boolean {
    return this.checkPermission('flight_user_not_sent_email');
  }
  get isFlightViewSignIn(): boolean {
    return this.checkPermission('flight_view_sign_in');
  }

  get isFlightManagementSubAgent(): boolean {
    return this.checkPermission('flight_management_sub_agent');
  }

  get isFlightManagementNewsOnly(): boolean {
    return this.checkPermission('flight_management_news_only');
  }

}
