import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import {
  FetchVehicleServiceResp,
  VehicleService,
  VehicleServiceStateType,
} from '@/pages/VehicleService/vehicle-service.data';
import {
  deleteVehicleService,
  fetchVehicleServices,
  getVehicleServiceDetail,
  updateVehicleService,
} from '@/pages/VehicleService/services';

export type VehicleServiceModelType = {
  namespace: string;
  state: VehicleServiceStateType;
  effects: {
    fetchVehicleServices: Effect;
    updateVehicleService: Effect;
    deleteVehicleService: Effect;
    getVehicleServiceDetail: Effect;
  };
  reducers: {
    fetchVehicleServicesSuccess: Reducer<VehicleServiceStateType>;
    getVehicleServiceDetailSuccess: Reducer<VehicleServiceStateType>;
  };
};

const Model: VehicleServiceModelType = {
  namespace: 'vehicleService',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchVehicleServices({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.cost.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchVehicleServiceResp = yield call(
        fetchVehicleServices,
        _.omitBy(query, _.isNil),
      );

      yield put({
        type: 'fetchVehicleServicesSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateVehicleService({ payload: { id, data } }, { call }) {
      yield call(updateVehicleService, id, data);
    },
    *getVehicleServiceDetail({ payload: { id, isLive } }, { call, put }) {
      const response: VehicleService = yield call(getVehicleServiceDetail, id, isLive);
      yield put({
        type: 'getVehicleServiceDetailSuccess',
        payload: { vehicleService: response },
      });
    },
    *deleteVehicleService({ payload }, { call, put }) {
      // @ts-ignore
      const response = yield call(deleteVehicleService, payload);

      if (response?.success) {
        message.success('The vehicle service has been deleted.');
        yield put({
          type: 'fetchVehicleServices',
        });
      }
    },
  },
  reducers: {
    fetchVehicleServicesSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getVehicleServiceDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
