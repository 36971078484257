import type { Reducer, Effect } from 'umi';
import _ from 'lodash';

import { fetchSubAgents, updateSubAgent, deleteSubAgent } from './services';
import type { FetchSubAgentResp, SubAgentStateType } from './sub-agent.data';
import type { ConnectState } from '@/models/connect';
import { message } from 'antd';

export type SubAgentModelType = {
  namespace: string;
  state: SubAgentStateType;
  effects: {
    fetchSubAgents: Effect;
    updateSubAgent: Effect;
    deleteSubAgent: Effect;
  };
  reducers: {
    fetchSubAgentsSuccess: Reducer<SubAgentStateType>;
  };
};

const Model: SubAgentModelType = {
  namespace: 'subAgent',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchSubAgents({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.user.query);

      const query = _.pickBy({ ...currentQuery, ...updatedParams }, (value) => value !== '');

      const response: FetchSubAgentResp = yield call(fetchSubAgents, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchSubAgentsSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateSubAgent({ payload: { tourSellId, data } }, { call }) {
      yield call(updateSubAgent, tourSellId, data);
    },
    *deleteSubAgent({ payload }, { call, put }) {
      const response = yield call(deleteSubAgent, payload);

      if (response?.success) {
        message.success('The sub agent has been deleted.').then(() => {});
        yield put({
          type: 'fetchSubAgents',
        });
      }
    },
  },
  reducers: {
    fetchSubAgentsSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
