import {CurrentUserDto} from './common/data.d/user.data.d';

export default function access(initialState: any) {
  const currentUser = CurrentUserDto.fromUser(initialState.currentUser);
  return {
    isMod: currentUser.isModAndAbove,
    isAdmin: currentUser.isAdminAndAbove,
    isSuperAdmin: currentUser.isSuperAdmin,
    isLodgingService: currentUser.isLodgingService,
    isTravelerService: currentUser.isTravelerService,
    isNotAgentAndCollaborator: currentUser.isNotAgentAndCollaborator,
    // Check by role
    // tour
    isTourCopy: currentUser.isTourCopy,
    isTourIndex: currentUser.isTourIndex,
    isTourCreate: currentUser.isTourCreate,
    isTourUpdate: currentUser.isTourUpdate,
    isTourDelete: currentUser.isTourDelete,
    isTourDetail: currentUser.isTourDetail,
    isTourPrint: currentUser.isTourDetail,
    // order
    isOrderIndex: currentUser.isOrderIndex,
    isOrderCreate: currentUser.isOrderCreate,
    isOrderUpdate: currentUser.isOrderUpdate,
    isOrderDelete: currentUser.isOrderDelete,
    isOrderDetail: currentUser.isOrderDetail,
    isOrderReceipt: currentUser.isOrderReceipt,
    isOrderPrint: currentUser.isOrderPrint,
    // visa order
    isVisaIndex: currentUser.isVisaIndex,
    isVisaCreate: currentUser.isVisaCreate,
    isVisaUpdate: currentUser.isVisaUpdate,
    isVisaDelete: currentUser.isVisaDelete,
    isVisaReceipt: currentUser.isVisaReceipt,
    isVisaPrint: currentUser.isVisaPrint,
    // combo order
    isComboOrderIndex: currentUser.isComboOrderIndex,
    isComboOrderCreate: currentUser.isComboOrderCreate,
    isComboOrderUpdate: currentUser.isComboOrderUpdate,
    isComboOrderDelete: currentUser.isComboOrderDelete,
    isComboOrderReceipt: currentUser.isComboOrderReceipt,
    isComboOrderPrint: currentUser.isComboOrderPrint,
    // hotel order
    isHotelOrderIndex: currentUser.isHotelOrderIndex,
    isHotelOrderCreate: currentUser.isHotelOrderCreate,
    isHotelOrderUpdate: currentUser.isHotelOrderUpdate,
    isHotelOrderDelete: currentUser.isHotelOrderDelete,
    isHotelOrderReceipt: currentUser.isHotelOrderReceipt,
    isHotelOrderPrint: currentUser.isHotelOrderPrint,
    // role
    isRoleIndex: currentUser.isRoleIndex,
    isRoleCreate: currentUser.isRoleCreate,
    isRoleUpdate: currentUser.isRoleUpdate,
    isRoleDelete: currentUser.isRoleDelete,
    // partner
    partnerLogo: currentUser.partnerLogo,
    // customer
    isCustomerIndex: currentUser.isCustomerIndex,
    isCustomerCreate: currentUser.isCustomerCreate,
    isCustomerUpdate: currentUser.isCustomerUpdate,
    isCustomerDelete: currentUser.isCustomerDelete,
    // cost
    isCostIndex: currentUser.isCostIndex,
    isCostCreate: currentUser.isCostCreate,
    isCostUpdate: currentUser.isCostUpdate,
    isCostDelete: currentUser.isCostDelete,
    isCostDetail: currentUser.isCostDetail,
    isCostPayment: currentUser.isCostPayment,
    // cost_category
    isCostCategory: currentUser.isCostDelete,
    // supplier
    isSupplierIndex: currentUser.isSupplierIndex,
    isSupplierCreate: currentUser.isSupplierCreate,
    isSupplierUpdate: currentUser.isSupplierUpdate,
    isSupplierDelete: currentUser.isSupplierDelete,
    // agent
    isAgentIndex: currentUser.isAgentIndex,
    isAgentCreate: currentUser.isAgentCreate,
    isAgentUpdate: currentUser.isAgentUpdate,
    isAgentDelete: currentUser.isAgentDelete,
    // collaborator
    isCtvIndex: currentUser.isCtvIndex,
    isCtvCreate: currentUser.isCtvCreate,
    isCtvUpdate: currentUser.isCtvUpdate,
    isCtvDelete: currentUser.isCtvDelete,
    // staff
    isStaffIndex: currentUser.isStaffIndex,
    isStaffCreate: currentUser.isStaffCreate,
    isStaffUpdate: currentUser.isStaffUpdate,
    isStaffDelete: currentUser.isStaffDelete,
    // activity_history
    isActivityIndex: currentUser.isActivityIndex,
    // dashboard
    isDashboard: currentUser.isDashboard,
    // visa_service
    isVisaService: currentUser.isVisaService,
    isVisaServiceCreate: currentUser.isVisaServiceCreate,
    isVisaServiceUpdate: currentUser.isVisaServiceUpdate,
    isVisaServiceDelete: currentUser.isVisaServiceDelete,
    // hotel_service
    isHotelService: currentUser.isHotelService,
    isHotelServiceCreate: currentUser.isHotelServiceCreate,
    isHotelServiceUpdate: currentUser.isHotelServiceUpdate,
    isHotelServiceDelete: currentUser.isHotelServiceDelete,
    // ticket_service
    isTicketService: currentUser.isTicketService,
    isTicketServiceCreate: currentUser.isTicketServiceCreate,
    isTicketServiceUpdate: currentUser.isTicketServiceUpdate,
    isTicketServiceDelete: currentUser.isTicketServiceDelete,
    // has_service
    hasService:
      currentUser.isVisaService || currentUser.isHotelService || currentUser.isTicketService,
    // has_service
    hasTour:
      currentUser.isTourIndex || currentUser.isPolicyTour || currentUser.isMenu,
    isPolicyTour: currentUser.isPolicyTour,
    isMenu: currentUser.isMenu,
    isTourSell: currentUser.isTourSell,
    hasOrder: currentUser.isOrderIndex || currentUser.isVisaIndex || currentUser.isHotelOrderIndex || currentUser.isComboOrderIndex,
  };
}
