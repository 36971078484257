import request from '@/utils/request';
import type {FetchPermissionResp} from "@/pages/Flight/Permission/permission.data";

export async function createPermission(params: any) {
  return request.post(`/flight/permissions`, {
    data: params,
  });
}

export async function fetchPermissions(params?: any): Promise<FetchPermissionResp> {
  return request.get('/flight/permissions', {
    params,
  });
}

export async function updatePermission(id: string, params: any) {
  return request.patch(`/flight/permissions/${id}`, {
    data: params,
  });
}

export async function deletePermission(id: string) {
  return request.delete(`/flight/permissions/${id}`);
}
