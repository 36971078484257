/** Request api: https://github.com/umijs/umi-request */
import { extend } from 'umi-request';
// import { notification } from 'antd';
import { getAccessToken } from './utils';

// const codeMessage: Record<number, string> = {
//   200: 'The server successfully returned the requested data.',
//   201: 'New or modified data is successful.',
//   202: 'A request has entered the background queue (asynchronous task).',
//   204: 'The data was deleted successfully.',
//   400: 'There was an error in the request sent, and the server did not create or modify the data.',
//   401: 'The user does not have permission (the token, username, password is wrong).',
//   403: 'The user is authorized, but access is forbidden.',
//   404: 'The request sent was for a record that did not exist, and the server did not operate.',
//   406: 'The requested format is not available.',
//   409: 'The request could not be processed because of conflict in the request.',
//   410: 'The requested resource is permanently deleted and will no longer be available.',
//   422: 'When creating an object, a validation error occurred.',
//   500: 'An error occurred in the server, please check the server.',
//   502: 'Gateway error.',
//   503: 'The service is unavailable, and the server is temporarily overloaded or maintained.',
//   504: 'The gateway timed out.',
// };

// const errorHandler = (error: { response: Response }): Response => {
//   const { response } = error;
//   if (response && response.status) {
//     const errorText = codeMessage[response.status] || response.statusText;

//     notification.error({
//       message: `Request error`,
//       description: errorText,
//     });
//   } else if (!response) {
//     notification.error({
//       description: 'Your network is abnormal and you cannot connect to the server',
//       message: 'Network anomaly',
//     });
//   }
//   return response;
// };

const request = extend({
  // errorHandler,
  // @ts-ignore
  prefix: REACT_APP_BASE_API_URL,
});

request.interceptors.request.use(
  (url, options) => {
    const token = getAccessToken();
    if (!token) {
      return { url, options };
    }
    return {
      url,
      options: {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    };
  },
  { global: true },
);

export const openRequest = extend({
  // errorHandler,
  // @ts-ignore
  prefix: REACT_APP_BASE_API_URL,
});

export const exportRequest = extend({
  // errorHandler,
  // @ts-ignore
  prefix: EXPORT_TOUR_API_URL,
});

export default request;
