import type { Reducer, Effect } from 'umi';
import _ from 'lodash';

import { deleteUser, fetchUsers, updateUser } from './services';
import type { FetchUserResp, UserStateType } from './user.data';
import type { ConnectState } from '@/models/connect';
import { message } from 'antd';

export type UserModelType = {
  namespace: string;
  state: UserStateType;
  effects: {
    fetchUsers: Effect;
    updateUser: Effect;
    deleteUser: Effect;
  };
  reducers: {
    fetchUsersSuccess: Reducer<UserStateType>;
  };
};

const Model: UserModelType = {
  namespace: 'user2',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchUsers({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.user.query);

      const query = _.pickBy({ ...currentQuery, ...updatedParams }, (value) => value !== '');

      const response: FetchUserResp = yield call(fetchUsers, query);

      yield put({
        type: 'fetchUsersSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateUser({ payload: { userId, data } }, { call }) {
      yield call(updateUser, userId, data);
    },
    *deleteUser({ payload }, { call, put }) {
      const response = yield call(deleteUser, payload);

      if (response?.success) {
        message.success('The user has been deleted.').then(() => {});
        yield put({
          type: 'fetchUsers',
        });
      }
    },
  },
  reducers: {
    fetchUsersSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
