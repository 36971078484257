const Airports = [
  {label: 'Ho Chi Minh City (SGN)', value: 'SGN'},
  {label: 'Ha Noi (HAN)', value: 'HAN'},
  {label: 'Da Lat (DLI)', value: 'DLI'},
  {label: 'Da Nang (DAD)', value: 'DAD'},
  {label: 'Nha Trang (CXR)', value: 'CXR'},
  {label: 'Dong Hoi (VDH)', value: 'VDH'},
  {label: 'Quang Ninh (VDO)', value: 'VDO'},
  {label: 'Buon Ma Thuat (BMW)', value: 'BMW'},
  {label: 'Chu Lai (VCL)', value: 'VCL'},
  {label: 'Hai Phong (HPH)', value: 'HPH'},
  {label: 'Ca Mau (CAH)', value: 'CAH'},
  {label: 'Con Dao (VSC)', value: 'VSC'},
  {label: 'Can Tho (VCA)', value: 'VCA'},
  {label: 'Dien Bien Phu (DIN)', value: 'DIN'},
  {label: 'Hue (HUI)', value: 'HUI'},
  {label: 'Quy Nhon (UIH)', value: 'UIH'},
  {label: 'Pleiku (PXU)', value: 'PXU'},
  {label: 'Phu Quoc (PQC)', value: 'PQC'},
  {label: 'Rach Gia (VKG)', value: 'VKG'},
  {label: 'Tuy Hoa (TBB)', value: 'TBB'},
  {label: 'Vinh (VII)', value: 'VII'},
  {label: 'Thanh Hoa (THD)', value: 'THD'},
  {label: 'New York (JFK)', value: 'JFK'},
  {label: 'Washington (WAS)', value: 'WAS'},
  {label: 'San Francisco (SFO)', value: 'SFO'},
  {label: 'Miami (MIA)', value: 'MIA'},
  {label: 'Bangkok (BKK)', value: 'BKK'},
  {label: 'Jakarta (CGK)', value: 'CGK'},
  {label: 'Kuala Lumpur (KUL)', value: 'KUL'},
  {label: 'Manila (MNL)', value: 'MNL'},
  {label: 'Singapore (SIN)', value: 'SIN'},
  {label: 'Denpasar-Bali Island (DPS)', value: 'DPS'},
  {label: 'Phnom Penh (PNH)', value: 'PNH'},
  {label: 'Luang Prabang (LPQ)', value: 'LPQ'},
  {label: 'Phuket (HKT)', value: 'HKT'},
  {label: 'Siem Reap (SAI)', value: 'SAI'},
  {label: 'Beijing (PEK)', value: 'PEK'},
  {label: 'Taipei (TPE)', value: 'TPE'},
  {label: 'Guangzhou (CAN)', value: 'CAN'},
  {label: 'Seoul (ICN)', value: 'ICN'},
  {label: 'Shanghai (SHA)', value: 'SHA'},
  {label: 'Tokyo Narita (NRT)', value: 'NRT'},
  {label: 'Busan (PUS)', value: 'PUS'},
  {label: 'Hong Kong (HKG)', value: 'HKG'},
  {label: 'Ngurah Rai (DPS)', value: 'DPS'},
  {label: 'Nogoya (NGO)', value: 'NGO'},
  {label: 'Shanghai Pudong (PVG)', value: 'PVG'},
  {label: 'Kaohsiung (KHH)', value: 'KHH'},
  {label: 'Dubai (BXB)', value: 'BXB'},
  { label: 'Sydney (SYD)', value: 'SYD' },
];

export default Airports;
