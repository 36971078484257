import _ from 'lodash';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import { fetchBanners, updateBanner } from './services';
import { FetchBannersResp, BannerStateType } from '@/pages/Banner/banner.data';

export type BannerModelType = {
  namespace: string;
  state: BannerStateType;
  effects: {
    fetchBanners: Effect;
    updateBanner: Effect;
  };
  reducers: {
    fetchBannersSuccess: Reducer<BannerStateType>;
  };
};

const Model: BannerModelType = {
  namespace: 'banner',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchBanners({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.role.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchBannersResp = yield call(fetchBanners, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchBannersSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateBanner({ payload: { id, data } }, { call }) {
      yield call(updateBanner, id, data);
    },
  },
  reducers: {
    fetchBannersSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
