// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/ec2-user/code/tripairgo-admin/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/home/ec2-user/code/tripairgo-admin/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/tour-sells",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/home/ec2-user/code/tripairgo-admin/src/layouts/BlankLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "Tour",
            "path": "/tour-sells/share/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourSell__Share' */'/home/ec2-user/code/tripairgo-admin/src/pages/TourSell/Share'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/live-visa-services",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/home/ec2-user/code/tripairgo-admin/src/layouts/BlankLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/live-visa-services/live/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaService__Live' */'/home/ec2-user/code/tripairgo-admin/src/pages/VisaService/Live'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/live-ticket-services",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/home/ec2-user/code/tripairgo-admin/src/layouts/BlankLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/live-ticket-services/live/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketService__Live' */'/home/ec2-user/code/tripairgo-admin/src/pages/TicketService/Live'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/live-hotel-services",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/home/ec2-user/code/tripairgo-admin/src/layouts/BlankLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/live-hotel-services/live/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelService__Live' */'/home/ec2-user/code/tripairgo-admin/src/pages/HotelService/Live'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/auth",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/home/ec2-user/code/tripairgo-admin/src/layouts/UserLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "Login",
            "path": "/auth/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Auth__login' */'/home/ec2-user/code/tripairgo-admin/src/pages/Auth/login'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/home/ec2-user/code/tripairgo-admin/src/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/home/ec2-user/code/tripairgo-admin/src/layouts/BasicLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/",
                "redirect": "/dashboard",
                "exact": true
              },
              {
                "path": "/dashboard",
                "name": "Dashboard",
                "icon": "home",
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/dashboard",
                    "redirect": "/dashboard/list",
                    "exact": true
                  },
                  {
                    "path": "/dashboard/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/Dashboard/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/settings",
                "name": "Quản trị",
                "icon": "ToolOutlined",
                "children": [
                  {
                    "path": "/staffs",
                    "name": "Danh sách Nhân sự",
                    "exact": true
                  },
                  {
                    "path": "/roles",
                    "name": "Nhóm quyền hạn",
                    "exact": true
                  }
                ],
                "exact": true
              },
              {
                "path": "/roles",
                "name": "Nhóm quyền hạn",
                "icon": "LockOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/roles",
                    "redirect": "/roles/list",
                    "exact": true
                  },
                  {
                    "path": "/roles/list",
                    "name": "Nhóm quyền hạn",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Role__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/Role/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/tour-collection",
                "name": "Tour",
                "icon": "AppstoreAddOutlined",
                "children": [
                  {
                    "path": "/tours",
                    "name": "Danh sách Tour",
                    "exact": true
                  },
                  {
                    "path": "/policies",
                    "name": "Chính sách Tour",
                    "exact": true
                  },
                  {
                    "path": "/menus",
                    "name": "Thực đơn Tour",
                    "exact": true
                  }
                ],
                "exact": true
              },
              {
                "path": "/tour-collection",
                "name": "Tour",
                "icon": "AppstoreAddOutlined",
                "access": "isTourIndex",
                "children": [
                  {
                    "path": "/tours",
                    "name": "Danh sách Tour",
                    "exact": true
                  }
                ],
                "exact": true
              },
              {
                "path": "/tours",
                "name": "Danh sách Tour",
                "icon": "car",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/tours",
                    "redirect": "/tours/list",
                    "exact": true
                  },
                  {
                    "path": "/tours/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tour__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/Tour/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tours/add",
                    "name": "Add tour",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tour__Add' */'/home/ec2-user/code/tripairgo-admin/src/pages/Tour/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tours/:id",
                    "name": "Chi tiết tour",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tour__Detail' */'/home/ec2-user/code/tripairgo-admin/src/pages/Tour/Detail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tours/edit/:id",
                    "name": "Chỉnh sửa tour",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tour__Edit' */'/home/ec2-user/code/tripairgo-admin/src/pages/Tour/Edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/tour-sells-collection",
                "name": "Tour đang mở bán",
                "icon": "ShoppingOutlined",
                "children": [
                  {
                    "path": "/tour-sell-open",
                    "name": "Đang mở bán",
                    "exact": true
                  },
                  {
                    "path": "/tour-sell-close",
                    "name": "Đã kết thúc",
                    "exact": true
                  }
                ],
                "exact": true
              },
              {
                "path": "/service-collection",
                "name": "Dịch vụ",
                "icon": "SwitcherOutlined",
                "children": [
                  {
                    "path": "/visa-services",
                    "name": "Visa",
                    "exact": true
                  },
                  {
                    "path": "/ticket-services",
                    "name": "Vé thăm quan",
                    "exact": true
                  },
                  {
                    "path": "/hotel-services",
                    "name": "Khách sạn",
                    "exact": true
                  }
                ],
                "exact": true
              },
              {
                "path": "/visa-services",
                "name": "Dịch vụ Visa",
                "icon": "ShoppingOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/visa-services",
                    "redirect": "/visa-services/list",
                    "exact": true
                  },
                  {
                    "path": "/visa-services/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaService__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/VisaService/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/visa-services/add",
                    "name": "Thêm mới visa",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaService__Add' */'/home/ec2-user/code/tripairgo-admin/src/pages/VisaService/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/visa-services/edit/:id",
                    "name": "Cập nhật visa",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaService__Edit' */'/home/ec2-user/code/tripairgo-admin/src/pages/VisaService/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/visa-services/:id",
                    "name": "Thông tin visa",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaService__Detail' */'/home/ec2-user/code/tripairgo-admin/src/pages/VisaService/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/ticket-services",
                "name": "Dịch vụ vé thăm quan",
                "icon": "ShoppingOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/ticket-services",
                    "redirect": "/ticket-services/list",
                    "exact": true
                  },
                  {
                    "path": "/ticket-services/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketService__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/TicketService/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/ticket-services/add",
                    "name": "Thêm mới vé thăm quan",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketService__Add' */'/home/ec2-user/code/tripairgo-admin/src/pages/TicketService/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/ticket-services/edit/:id",
                    "name": "Cập nhật vé thăm quan",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketService__Edit' */'/home/ec2-user/code/tripairgo-admin/src/pages/TicketService/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/ticket-services/:id",
                    "name": "Thông tin vé thăm quan",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketService__Detail' */'/home/ec2-user/code/tripairgo-admin/src/pages/TicketService/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/hotel-services",
                "name": "Dịch vụ Hotel",
                "icon": "ShoppingOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/hotel-services",
                    "redirect": "/hotel-services/list",
                    "exact": true
                  },
                  {
                    "path": "/hotel-services/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelService__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/HotelService/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/hotel-services/add",
                    "name": "Thêm mới hotel",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelService__Add' */'/home/ec2-user/code/tripairgo-admin/src/pages/HotelService/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/hotel-services/edit/:id",
                    "name": "Cập nhật hotel",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelService__Edit' */'/home/ec2-user/code/tripairgo-admin/src/pages/HotelService/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/hotel-services/:id",
                    "name": "Thông tin hotel",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelService__Detail' */'/home/ec2-user/code/tripairgo-admin/src/pages/HotelService/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/tour-sell-open",
                "name": "Đang mở bán",
                "icon": "ShoppingOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/tour-sell-open",
                    "redirect": "/tour-sell-open/list/open",
                    "exact": true
                  },
                  {
                    "path": "/tour-sell-open/list/:status",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourSell__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/TourSell/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tour-sell-open/add",
                    "name": "Thêm Tour mở bán",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourSell__Add' */'/home/ec2-user/code/tripairgo-admin/src/pages/TourSell/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tour-sell-open/:tour",
                    "name": "Chi tiết Tour mở bán",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourSell__Detail' */'/home/ec2-user/code/tripairgo-admin/src/pages/TourSell/Detail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tour-sell-open/edit/:tour",
                    "name": "Chỉnh sửa Tour mở bán",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourSell__Edit' */'/home/ec2-user/code/tripairgo-admin/src/pages/TourSell/Edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/tour-sell-close",
                "name": "Đã kết thúc",
                "icon": "ShoppingOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/tour-sell-close",
                    "redirect": "/tour-sell-close/list/close",
                    "exact": true
                  },
                  {
                    "path": "/tour-sell-close/list/:status",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourSell__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/TourSell/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/policies",
                "name": "Chính sách Tour",
                "icon": "fileProtect",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/policies",
                    "redirect": "/policies/list",
                    "exact": true
                  },
                  {
                    "path": "/policies/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Policy__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/Policy/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/menus",
                "name": "Thực đơn Tour",
                "icon": "read",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/menus",
                    "redirect": "/menus/list",
                    "exact": true
                  },
                  {
                    "path": "/menus/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Menu__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/Menu/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/staffs",
                "name": "Danh sách Nhân sự",
                "icon": "team",
                "hideChildrenInMenu": true,
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/staffs",
                    "redirect": "/staffs/list",
                    "exact": true
                  },
                  {
                    "path": "/staffs/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Staff__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/Staff/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/staffs/:id",
                    "name": "Thông tin tài khoản",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Staff__Detail' */'/home/ec2-user/code/tripairgo-admin/src/pages/Staff/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/order-collection",
                "name": "Danh sách đơn hàng",
                "icon": "ShoppingCartOutlined",
                "children": [
                  {
                    "path": "/orders",
                    "name": "Tour",
                    "exact": true
                  },
                  {
                    "path": "/visa",
                    "name": "Visa",
                    "exact": true
                  },
                  {
                    "path": "/hotel-orders",
                    "name": "Khách sạn",
                    "exact": true
                  },
                  {
                    "path": "/combo-orders",
                    "name": "Combo",
                    "exact": true
                  }
                ],
                "exact": true
              },
              {
                "path": "/visa",
                "name": "Danh sách đơn hàng Visa",
                "icon": "AuditOutlined",
                "hideChildrenInMenu": true,
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/visa",
                    "redirect": "/visa/list",
                    "exact": true
                  },
                  {
                    "path": "/visa/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visa__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/Visa/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/visa/add",
                    "name": "Thêm mới đơn hàng Visa",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visa__Add' */'/home/ec2-user/code/tripairgo-admin/src/pages/Visa/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/visa/edit/:id",
                    "name": "Chỉnh sửa đơn hàng Visa",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visa__Edit' */'/home/ec2-user/code/tripairgo-admin/src/pages/Visa/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/visa/:id",
                    "name": "Chi tiết đơn hàng Visa",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visa__Detail' */'/home/ec2-user/code/tripairgo-admin/src/pages/Visa/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/orders",
                "name": "Danh sách đơn hàng",
                "icon": "ShoppingCartOutlined",
                "hideChildrenInMenu": true,
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/orders",
                    "redirect": "/orders/list",
                    "exact": true
                  },
                  {
                    "path": "/orders/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/Order/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/orders/add",
                    "name": "Thêm mới đơn hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Add' */'/home/ec2-user/code/tripairgo-admin/src/pages/Order/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/orders/edit/:id",
                    "name": "Cập nhật đơn hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Edit' */'/home/ec2-user/code/tripairgo-admin/src/pages/Order/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/orders/:id",
                    "name": "Thông tin hóa đơn",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Detail' */'/home/ec2-user/code/tripairgo-admin/src/pages/Order/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/hotel-orders",
                "name": "Đơn hàng khách sạn",
                "icon": "ShoppingCartOutlined",
                "hideChildrenInMenu": true,
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/hotel-orders",
                    "redirect": "/hotel-orders/list",
                    "exact": true
                  },
                  {
                    "path": "/hotel-orders/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelOrder__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/HotelOrder/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/hotel-orders/add",
                    "name": "Thêm mới đơn hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelOrder__Add' */'/home/ec2-user/code/tripairgo-admin/src/pages/HotelOrder/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/hotel-orders/edit/:id",
                    "name": "Cập nhật đơn hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelOrder__Edit' */'/home/ec2-user/code/tripairgo-admin/src/pages/HotelOrder/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/hotel-orders/:id",
                    "name": "Thông tin hóa đơn",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelOrder__Detail' */'/home/ec2-user/code/tripairgo-admin/src/pages/HotelOrder/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/combo-orders",
                "name": "Đơn hàng combo",
                "icon": "ShoppingCartOutlined",
                "hideChildrenInMenu": true,
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/combo-orders",
                    "redirect": "/combo-orders/list",
                    "exact": true
                  },
                  {
                    "path": "/combo-orders/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ComboOrder__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/ComboOrder/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/combo-orders/add",
                    "name": "Thêm mới đơn hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ComboOrder__Add' */'/home/ec2-user/code/tripairgo-admin/src/pages/ComboOrder/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/combo-orders/edit/:id",
                    "name": "Cập nhật đơn hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ComboOrder__Edit' */'/home/ec2-user/code/tripairgo-admin/src/pages/ComboOrder/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/combo-orders/:id",
                    "name": "Thông tin hóa đơn",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ComboOrder__Detail' */'/home/ec2-user/code/tripairgo-admin/src/pages/ComboOrder/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/suppliers",
                "name": "Đối tác - Nhà cung cấp",
                "icon": "team",
                "access": "isTravelerService",
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/suppliers",
                    "redirect": "/suppliers/list",
                    "exact": true
                  },
                  {
                    "path": "/suppliers/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Supplier__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/Supplier/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/suppliers/detail/:id",
                    "name": "Chi tiết",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Supplier__Detail' */'/home/ec2-user/code/tripairgo-admin/src/pages/Supplier/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/customers",
                "name": "Khách hàng",
                "icon": "ContactsOutlined",
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/customers",
                    "redirect": "/customers/list",
                    "exact": true
                  },
                  {
                    "path": "/customers/list",
                    "name": "Khách hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/Customer/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/customers/:id",
                    "name": "Chi tiết khách hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Detail' */'/home/ec2-user/code/tripairgo-admin/src/pages/Customer/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/blogs",
                "name": "Blogs",
                "icon": "HighlightOutlined",
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/blogs",
                    "redirect": "/blogs/list",
                    "exact": true
                  },
                  {
                    "path": "/blogs/list",
                    "name": "Blog",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Blog__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/Blog/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/blogs/add",
                    "name": "Blog add",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Blog__Add' */'/home/ec2-user/code/tripairgo-admin/src/pages/Blog/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/blogs/edit/:id",
                    "name": "Chỉnh sửa blog",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Blog__Edit' */'/home/ec2-user/code/tripairgo-admin/src/pages/Blog/Edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/banners",
                "name": "Banners",
                "icon": "FundProjectionScreenOutlined",
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/banners",
                    "redirect": "/banners/list",
                    "exact": true
                  },
                  {
                    "path": "/banners/list",
                    "name": "Banner",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Banner__List' */'/home/ec2-user/code/tripairgo-admin/src/pages/Banner/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/banners/edit/:id",
                    "name": "Chỉnh sửa banner",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Banner__Edit' */'/home/ec2-user/code/tripairgo-admin/src/pages/Banner/Edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/ec2-user/code/tripairgo-admin/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/ec2-user/code/tripairgo-admin/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/ec2-user/code/tripairgo-admin/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
