import _ from 'lodash';
import { listBooking } from './services';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import type { FetchBookingResp, BookingStateType } from './booking.data';

export type BookingModelType = {
  namespace: string;
  state: BookingStateType;
  effects: {
    fetchBookings: Effect;
  };
  reducers: {
    fetchBookingsSuccess: Reducer<BookingStateType>;
  };
};

const Model: BookingModelType = {
  namespace: 'booking',

  state: {
    list: [],
    query: {},
    total: 0,
  },

  effects: {
    *fetchBookings({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.user.query);

      const query = _.pickBy({ ...currentQuery, ...updatedParams }, (value) => value !== '');

      const response: FetchBookingResp = yield call(listBooking, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchBookingsSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
  },
  reducers: {
    fetchBookingsSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
