// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/code/tripairgo-admin/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/root/code/tripairgo-admin/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/tour-sells",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/root/code/tripairgo-admin/src/layouts/BlankLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "Tour",
            "path": "/tour-sells/share/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourSell__Share' */'/root/code/tripairgo-admin/src/pages/TourSell/Share'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/live-visa-services",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/root/code/tripairgo-admin/src/layouts/BlankLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/live-visa-services/live/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaService__Live' */'/root/code/tripairgo-admin/src/pages/VisaService/Live'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/live-ticket-services",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/root/code/tripairgo-admin/src/layouts/BlankLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/live-ticket-services/live/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketService__Live' */'/root/code/tripairgo-admin/src/pages/TicketService/Live'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/live-hotel-services",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/root/code/tripairgo-admin/src/layouts/BlankLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/live-hotel-services/live/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelService__Live' */'/root/code/tripairgo-admin/src/pages/HotelService/Live'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/auth",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/root/code/tripairgo-admin/src/layouts/UserLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "Login",
            "path": "/auth/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Auth__login' */'/root/code/tripairgo-admin/src/pages/Auth/login'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/root/code/tripairgo-admin/src/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/root/code/tripairgo-admin/src/layouts/BasicLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/",
                "redirect": "/dashboard",
                "exact": true
              },
              {
                "path": "/dashboard",
                "name": "Dashboard",
                "icon": "home",
                "access": "isDashboard",
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/dashboard",
                    "redirect": "/dashboard/list",
                    "exact": true
                  },
                  {
                    "path": "/dashboard/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__List' */'/root/code/tripairgo-admin/src/pages/Dashboard/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/flight-collections",
                "name": "Nghiệp vụ bán vé",
                "icon": "ShoppingCartOutlined",
                "access": "isFlight",
                "children": [
                  {
                    "path": "/bookings/add",
                    "name": "Đặt giữ chỗ",
                    "exact": true,
                    "access": "isFlightBookingTicket"
                  },
                  {
                    "path": "/bookings/export",
                    "name": "Xuất vé",
                    "exact": true,
                    "access": "isFlightExportTicket"
                  },
                  {
                    "path": "/bookings/edit",
                    "name": "Sửa chỗ đặt vé",
                    "exact": true
                  },
                  {
                    "path": "/bookings/cancel",
                    "name": "Hũy chỗ đặt vé",
                    "exact": true
                  },
                  {
                    "path": "/bookings/search",
                    "name": "Tìm thông tin vé",
                    "exact": true
                  },
                  {
                    "path": "/bookings/print-ticket",
                    "name": "In hành trình",
                    "exact": true
                  },
                  {
                    "path": "/bookings/mail",
                    "name": "Gửi email",
                    "exact": true
                  },
                  {
                    "path": "/bookings/reservation-status",
                    "name": "Tình trạng đặt chỗ",
                    "exact": true
                  }
                ],
                "exact": true
              },
              {
                "path": "/agent-collections",
                "name": "Đại lý vé máy bay",
                "icon": "ShopOutlined",
                "access": "isFlight",
                "children": [
                  {
                    "path": "/agent-config",
                    "name": "Cấu hình đại lý",
                    "exact": true
                  },
                  {
                    "path": "/sub-agents",
                    "name": "Cấu hình đại lý cấp 2",
                    "exact": true
                  },
                  {
                    "path": "/users",
                    "name": "Quản lý người dùng",
                    "exact": true
                  },
                  {
                    "path": "/sign-in-settings",
                    "name": "Cấu hình Sign - In",
                    "exact": true
                  }
                ],
                "exact": true
              },
              {
                "path": "/accounting-collections",
                "name": "KT Công nợ",
                "icon": "DollarOutlined",
                "access": "isFlight",
                "children": [
                  {
                    "path": "/debit-report",
                    "name": "Công nợ KH",
                    "exact": true
                  },
                  {
                    "path": "/accounting/income-report",
                    "name": "Doanh thu chi tiết",
                    "exact": true
                  },
                  {
                    "path": "/deposit-top-up",
                    "name": "Đại lý ký quỹ",
                    "exact": true
                  },
                  {
                    "path": "/deposits",
                    "name": "Ký quỹ và giao dịch khác",
                    "exact": true
                  },
                  {
                    "path": "/deposit-monitor",
                    "name": "Xem số dư",
                    "exact": true
                  }
                ],
                "exact": true
              },
              {
                "path": "/agent-config",
                "name": "Cấu hình đại lý",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/agent-config",
                    "redirect": "/agent-config/edit",
                    "exact": true
                  },
                  {
                    "path": "/agent-config/edit",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__AgentConfig__Edit' */'/root/code/tripairgo-admin/src/pages/Flight/AgentConfig/Edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/agent-change-password",
                "name": "Đổi mật khẩu",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/agent-change-password",
                    "redirect": "/agent-change-password/index",
                    "exact": true
                  },
                  {
                    "path": "/agent-change-password/index",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__AgentChangePassword__Index' */'/root/code/tripairgo-admin/src/pages/Flight/AgentChangePassword/Index'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/bookings",
                "name": "Tình trạng đặt chỗ",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/bookings/reservation-status",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__Booking__List' */'/root/code/tripairgo-admin/src/pages/Flight/Booking/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/bookings/add",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__Booking__Add' */'/root/code/tripairgo-admin/src/pages/Flight/Booking/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/bookings/export",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__Booking__Export' */'/root/code/tripairgo-admin/src/pages/Flight/Booking/Export'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/bookings/completed/:id",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__Booking__Completed' */'/root/code/tripairgo-admin/src/pages/Flight/Booking/Completed'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/bookings/edit",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__Booking__Edit' */'/root/code/tripairgo-admin/src/pages/Flight/Booking/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/bookings/cancel",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__Booking__Cancel' */'/root/code/tripairgo-admin/src/pages/Flight/Booking/Cancel'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/bookings/search",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__Booking__Search' */'/root/code/tripairgo-admin/src/pages/Flight/Booking/Search'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/bookings/print-ticket",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__Booking__PrintTicket' */'/root/code/tripairgo-admin/src/pages/Flight/Booking/PrintTicket'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/bookings/mail",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__Booking__Email' */'/root/code/tripairgo-admin/src/pages/Flight/Booking/Email'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/time-limit-expire",
                "name": "Check Time Limit",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/time-limit-expire",
                    "redirect": "/time-limit-expire/list",
                    "exact": true
                  },
                  {
                    "path": "/time-limit-expire/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__TimeLimitExpire__List' */'/root/code/tripairgo-admin/src/pages/Flight/TimeLimitExpire/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/accounting/income-report",
                "name": "Doanh thu chi tiết",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/accounting/income-report",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__Accounting__IncomeReport' */'/root/code/tripairgo-admin/src/pages/Flight/Accounting/IncomeReport'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/debit-report",
                "name": "Công nợ KH",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/debit-report",
                    "redirect": "/debit-report/list",
                    "exact": true
                  },
                  {
                    "path": "/debit-report/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__DebitReport__List' */'/root/code/tripairgo-admin/src/pages/Flight/DebitReport/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/deposit-top-up",
                "name": "Đại lý ký quỹ",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/deposit-top-up",
                    "redirect": "/deposit-top-up/list",
                    "exact": true
                  },
                  {
                    "path": "/deposit-top-up/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__DepositTopUp__List' */'/root/code/tripairgo-admin/src/pages/Flight/DepositTopUp/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/deposit-monitor",
                "name": "Xem số dư",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/deposit-monitor",
                    "redirect": "/deposit-monitor/list",
                    "exact": true
                  },
                  {
                    "path": "/deposit-monitor/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__DepositMonitor__List' */'/root/code/tripairgo-admin/src/pages/Flight/DepositMonitor/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/deposits",
                "name": "Ký quỷ và giao dịch khác",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/deposits",
                    "redirect": "/deposits/list",
                    "exact": true
                  },
                  {
                    "path": "/deposits/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__Deposit__List' */'/root/code/tripairgo-admin/src/pages/Flight/Deposit/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/sub-agents",
                "name": "Danh sách đại lý",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/sub-agents",
                    "redirect": "/sub-agents/list",
                    "exact": true
                  },
                  {
                    "path": "/sub-agents/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__SubAgent__List' */'/root/code/tripairgo-admin/src/pages/Flight/SubAgent/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/permissions",
                "name": "Phân quyền",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/permissions",
                    "redirect": "/permissions/list",
                    "exact": true
                  },
                  {
                    "path": "/permissions/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__Permission__List' */'/root/code/tripairgo-admin/src/pages/Flight/Permission/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/users",
                "name": "Quản lý người dùng",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/users",
                    "redirect": "/users/list",
                    "exact": true
                  },
                  {
                    "path": "/users/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__User__List' */'/root/code/tripairgo-admin/src/pages/Flight/User/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/sign-in-settings",
                "name": "Cấu hình Sign-In",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/sign-in-settings",
                    "redirect": "/sign-in-settings/list",
                    "exact": true
                  },
                  {
                    "path": "/sign-in-settings/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Flight__SignInSetting__List' */'/root/code/tripairgo-admin/src/pages/Flight/SignInSetting/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/roles",
                "name": "Nhóm quyền hạn",
                "icon": "LockOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/roles",
                    "redirect": "/roles/list",
                    "exact": true
                  },
                  {
                    "path": "/roles/list",
                    "name": "Nhóm quyền hạn",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Role__List' */'/root/code/tripairgo-admin/src/pages/Role/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/tour-collection",
                "name": "Tour",
                "icon": "AppstoreAddOutlined",
                "access": "hasTour",
                "routes": [
                  {
                    "path": "/tours",
                    "name": "Danh sách Tour",
                    "access": "isTourIndex",
                    "exact": true
                  },
                  {
                    "path": "/policies",
                    "name": "Chính sách Tour",
                    "access": "isPolicyTour",
                    "exact": true
                  },
                  {
                    "path": "/menus",
                    "name": "Thực đơn Tour",
                    "access": "isMenu",
                    "exact": true
                  }
                ]
              },
              {
                "path": "/tours",
                "name": "Danh sách Tour",
                "icon": "car",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/tours",
                    "redirect": "/tours/list",
                    "exact": true
                  },
                  {
                    "path": "/tours/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tour__List' */'/root/code/tripairgo-admin/src/pages/Tour/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tours/add",
                    "name": "Add tour",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tour__Add' */'/root/code/tripairgo-admin/src/pages/Tour/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tours/:id",
                    "name": "Chi tiết tour",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tour__Detail' */'/root/code/tripairgo-admin/src/pages/Tour/Detail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tours/edit/:id",
                    "name": "Chỉnh sửa tour",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tour__Edit' */'/root/code/tripairgo-admin/src/pages/Tour/Edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/tour-sells-collection",
                "name": "Tour đang mở bán",
                "icon": "ShoppingOutlined",
                "access": "hasTourSell",
                "children": [
                  {
                    "path": "/tour-sell-open",
                    "name": "Đang mở bán",
                    "exact": true
                  },
                  {
                    "path": "/tour-sell-close",
                    "name": "Đã kết thúc",
                    "exact": true
                  }
                ],
                "exact": true
              },
              {
                "path": "/service-collection",
                "name": "Dịch vụ",
                "icon": "SwitcherOutlined",
                "access": "hasService",
                "routes": [
                  {
                    "path": "/visa-services",
                    "name": "Visa",
                    "exact": true,
                    "access": "isVisaService"
                  },
                  {
                    "path": "/ticket-services",
                    "name": "Vé thăm quan",
                    "exact": true,
                    "access": "isTicketService"
                  },
                  {
                    "path": "/hotel-services",
                    "name": "Khách sạn",
                    "exact": true,
                    "access": "isHotelService"
                  },
                  {
                    "path": "/tour-guides",
                    "name": "Hướng dẫn viên",
                    "exact": true
                  },
                  {
                    "path": "/vehicle-services",
                    "name": "Xe du lịch",
                    "exact": true
                  }
                ]
              },
              {
                "path": "/visa-services",
                "name": "Dịch vụ Visa",
                "icon": "ShoppingOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/visa-services",
                    "redirect": "/visa-services/list",
                    "exact": true
                  },
                  {
                    "path": "/visa-services/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaService__List' */'/root/code/tripairgo-admin/src/pages/VisaService/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/visa-services/add",
                    "name": "Thêm mới visa",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaService__Add' */'/root/code/tripairgo-admin/src/pages/VisaService/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/visa-services/edit/:id",
                    "name": "Cập nhật visa",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaService__Edit' */'/root/code/tripairgo-admin/src/pages/VisaService/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/visa-services/:id",
                    "name": "Thông tin visa",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VisaService__Detail' */'/root/code/tripairgo-admin/src/pages/VisaService/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/ticket-services",
                "name": "Dịch vụ vé thăm quan",
                "icon": "ShoppingOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/ticket-services",
                    "redirect": "/ticket-services/list",
                    "exact": true
                  },
                  {
                    "path": "/ticket-services/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketService__List' */'/root/code/tripairgo-admin/src/pages/TicketService/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/ticket-services/add",
                    "name": "Thêm mới vé thăm quan",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketService__Add' */'/root/code/tripairgo-admin/src/pages/TicketService/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/ticket-services/edit/:id",
                    "name": "Cập nhật vé thăm quan",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketService__Edit' */'/root/code/tripairgo-admin/src/pages/TicketService/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/ticket-services/:id",
                    "name": "Thông tin vé thăm quan",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TicketService__Detail' */'/root/code/tripairgo-admin/src/pages/TicketService/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/hotel-services",
                "name": "Dịch vụ Hotel",
                "icon": "ShoppingOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/hotel-services",
                    "redirect": "/hotel-services/list",
                    "exact": true
                  },
                  {
                    "path": "/hotel-services/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelService__List' */'/root/code/tripairgo-admin/src/pages/HotelService/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/hotel-services/add",
                    "name": "Thêm mới hotel",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelService__Add' */'/root/code/tripairgo-admin/src/pages/HotelService/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/hotel-services/edit/:id",
                    "name": "Cập nhật hotel",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelService__Edit' */'/root/code/tripairgo-admin/src/pages/HotelService/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/hotel-services/:id",
                    "name": "Thông tin hotel",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelService__Detail' */'/root/code/tripairgo-admin/src/pages/HotelService/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/tour-guides",
                "name": "Hướng dẫn viên",
                "icon": "UserOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/tour-guides",
                    "redirect": "/tour-guides/list",
                    "exact": true
                  },
                  {
                    "path": "/tour-guides/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourGuide__List' */'/root/code/tripairgo-admin/src/pages/TourGuide/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tour-guides/add",
                    "name": "Thêm mới",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourGuide__Add' */'/root/code/tripairgo-admin/src/pages/TourGuide/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tour-guides/edit/:id",
                    "name": "Cập nhật",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourGuide__Edit' */'/root/code/tripairgo-admin/src/pages/TourGuide/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tour-guides/:id",
                    "name": "Thông tin",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourGuide__Detail' */'/root/code/tripairgo-admin/src/pages/TourGuide/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/vehicle-services",
                "name": "Xe du lịch",
                "icon": "UserOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/vehicle-services",
                    "redirect": "/vehicle-services/list",
                    "exact": true
                  },
                  {
                    "path": "/vehicle-services/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VehicleService__List' */'/root/code/tripairgo-admin/src/pages/VehicleService/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/vehicle-services/add",
                    "name": "Thêm mới",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VehicleService__Add' */'/root/code/tripairgo-admin/src/pages/VehicleService/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/vehicle-services/edit/:id",
                    "name": "Cập nhật",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VehicleService__Edit' */'/root/code/tripairgo-admin/src/pages/VehicleService/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/vehicle-services/:id",
                    "name": "Thông tin",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__VehicleService__Detail' */'/root/code/tripairgo-admin/src/pages/VehicleService/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/tour-sell-open",
                "name": "Đang mở bán",
                "icon": "ShoppingOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/tour-sell-open",
                    "redirect": "/tour-sell-open/list/open",
                    "exact": true
                  },
                  {
                    "path": "/tour-sell-open/list/:status",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourSell__List' */'/root/code/tripairgo-admin/src/pages/TourSell/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tour-sell-open/add",
                    "name": "Thêm Tour mở bán",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourSell__Add' */'/root/code/tripairgo-admin/src/pages/TourSell/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tour-sell-open/:tour",
                    "name": "Chi tiết Tour mở bán",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourSell__Detail' */'/root/code/tripairgo-admin/src/pages/TourSell/Detail'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/tour-sell-open/edit/:tour",
                    "name": "Chỉnh sửa Tour mở bán",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourSell__Edit' */'/root/code/tripairgo-admin/src/pages/TourSell/Edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/tour-sell-close",
                "name": "Đã kết thúc",
                "icon": "ShoppingOutlined",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/tour-sell-close",
                    "redirect": "/tour-sell-close/list/close",
                    "exact": true
                  },
                  {
                    "path": "/tour-sell-close/list/:status",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__TourSell__List' */'/root/code/tripairgo-admin/src/pages/TourSell/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/policies",
                "name": "Chính sách Tour",
                "icon": "fileProtect",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/policies",
                    "redirect": "/policies/list",
                    "exact": true
                  },
                  {
                    "path": "/policies/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Policy__List' */'/root/code/tripairgo-admin/src/pages/Policy/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/menus",
                "name": "Thực đơn Tour",
                "icon": "read",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/menus",
                    "redirect": "/menus/list",
                    "exact": true
                  },
                  {
                    "path": "/menus/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Menu__List' */'/root/code/tripairgo-admin/src/pages/Menu/List'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/staffs",
                "name": "Danh sách Nhân sự",
                "icon": "team",
                "hideChildrenInMenu": true,
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/staffs",
                    "redirect": "/staffs/list",
                    "exact": true
                  },
                  {
                    "path": "/staffs/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Staff__List' */'/root/code/tripairgo-admin/src/pages/Staff/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/staffs/:id",
                    "name": "Thông tin tài khoản",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Staff__Detail' */'/root/code/tripairgo-admin/src/pages/Staff/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/order-collection",
                "name": "Danh sách đơn hàng",
                "icon": "ShoppingCartOutlined",
                "access": "hasOrder",
                "routes": [
                  {
                    "path": "/orders",
                    "name": "Tour",
                    "exact": true,
                    "access": "isOrderIndex"
                  },
                  {
                    "path": "/visa",
                    "name": "Visa",
                    "exact": true,
                    "access": "isVisaIndex"
                  },
                  {
                    "path": "/hotel-orders",
                    "name": "Khách sạn",
                    "exact": true,
                    "access": "isHotelOrderIndex"
                  },
                  {
                    "path": "/combo-orders",
                    "name": "Combo",
                    "exact": true,
                    "access": "isComboOrderIndex"
                  }
                ]
              },
              {
                "path": "/visa",
                "name": "Danh sách đơn hàng Visa",
                "icon": "AuditOutlined",
                "hideChildrenInMenu": true,
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/visa",
                    "redirect": "/visa/list",
                    "exact": true
                  },
                  {
                    "path": "/visa/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visa__List' */'/root/code/tripairgo-admin/src/pages/Visa/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/visa/add",
                    "name": "Thêm mới đơn hàng Visa",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visa__Add' */'/root/code/tripairgo-admin/src/pages/Visa/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/visa/edit/:id",
                    "name": "Chỉnh sửa đơn hàng Visa",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visa__Edit' */'/root/code/tripairgo-admin/src/pages/Visa/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/visa/:id",
                    "name": "Chi tiết đơn hàng Visa",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Visa__Detail' */'/root/code/tripairgo-admin/src/pages/Visa/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/orders",
                "name": "Danh sách đơn hàng",
                "icon": "ShoppingCartOutlined",
                "hideChildrenInMenu": true,
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/orders",
                    "redirect": "/orders/list",
                    "exact": true
                  },
                  {
                    "path": "/orders/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__List' */'/root/code/tripairgo-admin/src/pages/Order/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/orders/add",
                    "name": "Thêm mới đơn hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Add' */'/root/code/tripairgo-admin/src/pages/Order/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/orders/edit/:id",
                    "name": "Cập nhật đơn hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Edit' */'/root/code/tripairgo-admin/src/pages/Order/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/orders/:id",
                    "name": "Thông tin hóa đơn",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Order__Detail' */'/root/code/tripairgo-admin/src/pages/Order/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/hotel-orders",
                "name": "Đơn hàng khách sạn",
                "icon": "ShoppingCartOutlined",
                "hideChildrenInMenu": true,
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/hotel-orders",
                    "redirect": "/hotel-orders/list",
                    "exact": true
                  },
                  {
                    "path": "/hotel-orders/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelOrder__List' */'/root/code/tripairgo-admin/src/pages/HotelOrder/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/hotel-orders/add",
                    "name": "Thêm mới đơn hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelOrder__Add' */'/root/code/tripairgo-admin/src/pages/HotelOrder/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/hotel-orders/edit/:id",
                    "name": "Cập nhật đơn hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelOrder__Edit' */'/root/code/tripairgo-admin/src/pages/HotelOrder/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/hotel-orders/:id",
                    "name": "Thông tin hóa đơn",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HotelOrder__Detail' */'/root/code/tripairgo-admin/src/pages/HotelOrder/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/combo-orders",
                "name": "Đơn hàng combo",
                "icon": "ShoppingCartOutlined",
                "hideChildrenInMenu": true,
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/combo-orders",
                    "redirect": "/combo-orders/list",
                    "exact": true
                  },
                  {
                    "path": "/combo-orders/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ComboOrder__List' */'/root/code/tripairgo-admin/src/pages/ComboOrder/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/combo-orders/add",
                    "name": "Thêm mới đơn hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ComboOrder__Add' */'/root/code/tripairgo-admin/src/pages/ComboOrder/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/combo-orders/edit/:id",
                    "name": "Cập nhật đơn hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ComboOrder__Edit' */'/root/code/tripairgo-admin/src/pages/ComboOrder/Edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/combo-orders/:id",
                    "name": "Thông tin hóa đơn",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ComboOrder__Detail' */'/root/code/tripairgo-admin/src/pages/ComboOrder/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/suppliers",
                "name": "Đối tác - Nhà cung cấp",
                "icon": "team",
                "access": "isTravelerService",
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/suppliers",
                    "redirect": "/suppliers/list",
                    "exact": true
                  },
                  {
                    "path": "/suppliers/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Supplier__List' */'/root/code/tripairgo-admin/src/pages/Supplier/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/suppliers/detail/:id",
                    "name": "Chi tiết",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Supplier__Detail' */'/root/code/tripairgo-admin/src/pages/Supplier/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/customers",
                "name": "Khách hàng",
                "icon": "ContactsOutlined",
                "access": "hasCustomer",
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/customers",
                    "redirect": "/customers/list",
                    "exact": true
                  },
                  {
                    "path": "/customers/list",
                    "name": "Khách hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__List' */'/root/code/tripairgo-admin/src/pages/Customer/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/customers/:id",
                    "name": "Chi tiết khách hàng",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Customer__Detail' */'/root/code/tripairgo-admin/src/pages/Customer/Detail'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/blogs",
                "name": "Blogs",
                "access": "hasBlog",
                "icon": "HighlightOutlined",
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/blogs",
                    "redirect": "/blogs/list",
                    "exact": true
                  },
                  {
                    "path": "/blogs/list",
                    "name": "Blog",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Blog__List' */'/root/code/tripairgo-admin/src/pages/Blog/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/blogs/add",
                    "name": "Blog add",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Blog__Add' */'/root/code/tripairgo-admin/src/pages/Blog/Add'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/blogs/edit/:id",
                    "name": "Chỉnh sửa blog",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Blog__Edit' */'/root/code/tripairgo-admin/src/pages/Blog/Edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/settings",
                "name": "Quản trị",
                "access": "hasSetting",
                "icon": "ToolOutlined",
                "children": [
                  {
                    "path": "/staffs",
                    "name": "Danh sách Nhân sự",
                    "exact": true
                  },
                  {
                    "path": "/roles",
                    "name": "Nhóm quyền hạn",
                    "exact": true
                  }
                ],
                "exact": true
              },
              {
                "path": "/banners",
                "name": "Banners",
                "access": "hasBanner",
                "icon": "FundProjectionScreenOutlined",
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/banners",
                    "redirect": "/banners/list",
                    "exact": true
                  },
                  {
                    "path": "/banners/list",
                    "name": "Banner",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Banner__List' */'/root/code/tripairgo-admin/src/pages/Banner/List'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/banners/edit/:id",
                    "name": "Chỉnh sửa banner",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Banner__Edit' */'/root/code/tripairgo-admin/src/pages/Banner/Edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/root/code/tripairgo-admin/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/root/code/tripairgo-admin/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/root/code/tripairgo-admin/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
