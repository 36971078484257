import request from '@/utils/request';
import type {FetchDepositTopupResp} from "@/pages/Flight/DepositTopUp/deposit-topup.data";

export async function createDepositTopup(params: any) {
  return request.post(`/flight/deposit-topups`, {
    data: params,
  });
}

export async function fetchDepositTopups(params?: any): Promise<FetchDepositTopupResp> {
  return request.get('/flight/deposit-topups', {
    params,
  });
}

export async function updateDepositTopup(id: string, params: any) {
  return request.patch(`/flight/deposit-topups/${id}`, {
    data: params,
  });
}

export async function deleteDepositTopup(id: string) {
  return request.delete(`/flight/deposit-topups/${id}`);
}
