import type { Reducer, Effect } from 'umi';
import _ from 'lodash';

import { fetchDepositTopups, updateDepositTopup, deleteDepositTopup } from './services';
import type { FetchDepositTopupResp, DepositTopupStateType } from './deposit-topup.data';
import type { ConnectState } from '@/models/connect';
import { message } from 'antd';

export type DepositTopupModelType = {
  namespace: string;
  state: DepositTopupStateType;
  effects: {
    fetchDepositTopups: Effect;
    updateDepositTopup: Effect;
    deleteDepositTopup: Effect;
  };
  reducers: {
    fetchDepositTopupsSuccess: Reducer<DepositTopupStateType>;
  };
};

const Model: DepositTopupModelType = {
  namespace: 'depositTopup',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchDepositTopups({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.depositTopup.query);

      const query = _.pickBy({ ...currentQuery, ...updatedParams }, (value) => value !== '');

      const response: FetchDepositTopupResp = yield call(fetchDepositTopups, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchDepositTopupsSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateDepositTopup({ payload: { id, data } }, { call }) {
      yield call(updateDepositTopup, id, data);
    },
    *deleteDepositTopup({ payload }, { call, put }) {
      const response = yield call(deleteDepositTopup, payload);

      if (response?.success) {
        message.success('The deposit topup has been deleted.').then(() => {});
        yield put({
          type: 'fetchDepositTopups',
        });
      }
    },
  },
  reducers: {
    fetchDepositTopupsSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
