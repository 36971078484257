import request from '@/utils/request';
import { FetchTourGuideResp, TourGuide } from '@/pages/TourGuide/tour-guide.data';

export async function createTourGuide(params: any) {
  return request.post(`/cms/tour-guides`, {
    data: params,
  });
}

export async function fetchTourGuides(params?: any): Promise<FetchTourGuideResp> {
  return request.get('/cms/tour-guides', {
    params,
  });
}

export async function getTourGuideDetail(id: string): Promise<TourGuide> {
  return request.get(`/cms/tour-guides/${id}`);
}

export async function updateTourGuide(id: string, params?: any): Promise<any> {
  return request.patch(`/cms/tour-guides/${id}`, {
    data: params,
  });
}

export async function deleteTourGuide(id: string): Promise<any> {
  return request.delete(`/cms/tour-guides/${id}`);
}
