import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import { FetchHotelServiceResp } from '@/pages/HotelService/hotel-service.data';
import { TourGuide, TourGuideStateType } from '@/pages/TourGuide/tour-guide.data';
import { fetchTourGuides, getTourGuideDetail, updateTourGuide } from '@/pages/TourGuide/services';

export type TourGuideModelType = {
  namespace: string;
  state: TourGuideStateType;
  effects: {
    fetchTourGuides: Effect;
    updateTourGuide: Effect;
    deleteTourGuide: Effect;
    getTourGuideDetail: Effect;
  };
  reducers: {
    fetchTourGuidesSuccess: Reducer<TourGuideStateType>;
    getTourGuideDetailSuccess: Reducer<TourGuideStateType>;
  };
};

const Model: TourGuideModelType = {
  namespace: 'tourGuide',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchTourGuides({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.cost.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchHotelServiceResp = yield call(fetchTourGuides, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchTourGuidesSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateTourGuide({ payload: { id, data } }, { call }) {
      yield call(updateTourGuide, id, data);
    },
    *getTourGuideDetail({ payload: { id, isLive } }, { call, put }) {
      const response: TourGuide = yield call(getTourGuideDetail, id, isLive);
      yield put({
        type: 'getTourGuideDetailSuccess',
        payload: { tourGuide: response },
      });
    },
    *deleteTourGuide({ payload }, { call, put }) {
      // @ts-ignore
      const response = yield call(deleteTourGuide, payload);

      if (response?.success) {
        message.success('The hotel service has been deleted.');
        yield put({
          type: 'fetchTourGuides',
        });
      }
    },
  },
  reducers: {
    fetchTourGuidesSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getTourGuideDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
